import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SearchInputOutline } from "../../components/CustomInput";
import { EMPTY_ARRAY } from "../../utils/Constants";
import { NoDataFound } from "../../components/NoDataFound";
import { Icon, Pagination, Slide } from "@mui/material";
import SkeletonTypography from "../../components/Skeleton";
import { debounce } from "../../utils/Utils";
import { DashboardCard, UsersCard } from "components/Cards";
import { NotificationsOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { notificationsApi } from "apis/services/NotificationsApi";
import { NotificationsAccordion } from "components/Accordion";

const Notifications = () => {
  const [page, setPage] = useState(1);

  const [searchPhrase, setSearchPhrase] = useState("");

  const { data, isLoading } = notificationsApi.useGetNotificationsQuery({
    queryKeyValue: `?${searchPhrase}&page=${page}`
  });
  
  const {
    data: notificationsStatistics,
    isLoading: isGettingnotificationsStatistics,
    refetch
  } = notificationsApi.useGetNotificationsStatisticsQuery({});

  const [updateReadStatus, { isLoading: isUpdatingStatus }] =
    notificationsApi.useUpdateNotificationReadStatusMutation();

  const nomalizedNotification = data?.data?.data;
  const notificationsStatisticsData = notificationsStatistics?.data;

  const handleViewNotification = (notificationId) => {
    updateReadStatus(`?notificationId=${notificationId}`);
  };

  useEffect(() => {
    // Check if the trigger condition is met (e.g., triggerState is true)
    if (nomalizedNotification) {
      refetch(); // Trigger the query
    }
  }, [nomalizedNotification, refetch]);

  // Search and FIlter
  const search = useCallback(async (e) => {
    if (e.target.value.length > 0) {
      try {
        setSearchPhrase(searchPhrase + `search=${e.target.value}`);
      } catch (err) {
        toast.error(err);
      }
    } else {
      setSearchPhrase(searchPhrase);
    }
  }, []);

  // memoize the debounce call with useMemo
  const debouncedOnChange = useMemo(() => {
    return debounce(search, 1000);
  }, [search]);

  const handleChangePage = (event, newPage) => {
    try {
      setPage(newPage);
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <>
      <>
        <Slide direction="left" in={true} mountOnEnter unmountOnExit>
          <div className="flex flex-wrap">
            <DashboardCard
              icon={<NotificationsOutlined />}
              title={"Total Notifications"}
              count={notificationsStatisticsData?.totalNotifications || 0}
            />
            <DashboardCard
              icon={<NotificationsOutlined />}
              title={"Read Notifications"}
              count={notificationsStatisticsData?.readNotifications || 0}
            />
            <DashboardCard
              icon={<NotificationsOutlined />}
              title={"Unread Notifications"}
              count={notificationsStatisticsData?.unreadNotifications || 0}
            />
          </div>
        </Slide>
        <br />
        <SearchInputOutline
          prefix={<Icon sx={{ color: "white" }}>search</Icon>}
          placeholder="Search..."
          onChange={debouncedOnChange}
        />

        <br />

        <p className="text-lg font-bold">Notifications</p>

        <div className="flex flex-wrap justify-center self-center">
          {nomalizedNotification?.map((item, index) => {
            return (
              <NotificationsAccordion key={index} item={item} index={index} />
            );
          })}
          {nomalizedNotification?.length < 1 ? <NoDataFound width={100} /> : null}
          {isLoading ? (
            <>
              {EMPTY_ARRAY?.map((item, i) => {
                return <SkeletonTypography key={i} />;
              })}
            </>
          ) : null}
        </div>

        {/* Paginator */}
        <div className="flex lg:flex-row flex-col items-center justify-between mt-3 lg:space-x-4 space-y-4 mx-6">
          <h1 className="text-base font-bold">
            Data: {nomalizedNotification?.length} of {data?.data?.totalData}
          </h1>
          <div className="flex justify-end space-x-4">
            <Pagination
              count={data?.data?.totalPages || 0}
              variant="outlined"
              color="primary"
              showFirstButton
              showLastButton
              onChange={handleChangePage}
            />
          </div>
        </div>
      </>
    </>
  );
};

export default Notifications;
