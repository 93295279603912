import { Button, Icon, IconButton } from "@mui/material";
import { daisyApi } from "apis/services/Daisy";
import { CustomInputOutline } from "components/CustomInput";
import { NoDataFound } from "components/NoDataFound";
import SkeletonTypography from "components/Skeleton";
import { ColorRingSpinner, SmallSpinner } from "components/Spinner";
import useAuthUser from "hooks/useAuthUser";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { COLORS } from "utils/Colors";
import { APP_ROUTES_PATH, EMPTY_ARRAY } from "utils/Constants";
import { debounce, FormatCurrency } from "utils/Utils";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { walletApi } from "apis/services/WalletApi";

const NumbersServiceList = () => {
  const { user } = useAuthUser();
  const navigate = useNavigate();
  const [isSelectedItemId, setIsSelectedItemId] = useState("");
  const [onGoingRentalList, setOngoingRentalList] = useState(false);
  const [loader, setLoader] = useState(false);
  // const [refetchBalance, setRefetchBalance] = useState(false);

  const [searchPhrase, setSearchPhrase] = useState("");

  const { data, isLoading } = daisyApi.useGetServiceListQuery({
    queryKeyValue: `?${searchPhrase}`
  });
  const normalizedData = data?.data;

  const [filteredData, setFilteredData] = useState(normalizedData);

  const [likeUnlike, { isLoading: isLiking }] =
    daisyApi.useLikeUnlikeMutation();

  const [rentANumber, { isLoading: isRentingNumber }] =
    daisyApi.useRentANumberMutation();

  const {
    data: wallet,
    isLoading: isLoadingWallet,
    refetch: refetchWallet
  } = walletApi.useGetMyWalletQuery({});

  const normalizedWallet = wallet?.data;

  // Search and FIlter
  const search = useCallback(async (e) => {
    if (normalizedData && e.target.value.length > 0) {
      try {
        const value = e.target.value;
        setLoader(true);
        const newData = normalizedData.filter((item) => {
          const itemData = item.name.toUpperCase();
          const textData = value.toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
        setFilteredData(newData);
        return setLoader(false);
      } catch (err) {
        setLoader(false);
        toast.error(err);
      }
    } else {
      setFilteredData(normalizedData);
    }
  }, [normalizedData]);

  // memoize the debounce call with useMemo
  const debouncedOnChange = useMemo(() => {
    return debounce(search, 500);
  }, [search]);

  const handleLikeUnlike = async (id) => {
    setIsSelectedItemId(id);
    await likeUnlike(id);
  };

  const handleRentNumber = async (item) => {
    setIsSelectedItemId(item._id);
    try {
      const result = await rentANumber(
        `service=${item.serviceId}&max_price=5.5`
      ).unwrap();
      if (result.status === "OK") {
        navigate(APP_ROUTES_PATH.Numbers_Rent_History);
        setOngoingRentalList(true);
      }
      toast.error(result.message);
    } catch (err) {
      console.error("Error:", err);
      toast.error(err.data.message);

      // Handle error (e.g., show an error message)
    }
  };

  useEffect(() => {
    setLoader(false);
    setFilteredData(normalizedData);
  }, [data, normalizedData]);

  return (
    <>
      <div className="flex justify-start">
        <div className="bg-black/50 p-4 rounded-lg">
          <p>
            {FormatCurrency(normalizedWallet?.availableBalance || 0)}(
            {FormatCurrency(normalizedWallet?.ledgerBalance || 0)})
          </p>
        </div>
      </div>

      <div className={onGoingRentalList ? `hidden` : "visible"}>
        <CustomInputOutline
          prefix={<Icon sx={{ color: "white" }}>search</Icon>}
          placeholder="Search..."
          outlinecolor="#fff"
          onChange={debouncedOnChange}
          loader={loader ? <SmallSpinner /> : null}
        />

        <br />

        <p className="text-sm font-bold mb-2">
          Rent a phone for 7 minutes. Credits are only used if you receive the
          SMS code.
        </p>

        <div className="w-full overflow-x-auto">
          <div
            className="grid grid-cols-3 w-full p-2 rounded-t-2xl"
            style={{ backgroundColor: COLORS.primary, color: "white" }}
          >
            <strong>Service</strong>
            <strong>Price</strong>
            <strong>Action</strong>
          </div>
          {filteredData?.map((item, index) => {
            return (
              <div
                key={index}
                className="grid grid-cols-3 border-b rounded-b-xl py-4 w-full"
                onClick={() => setIsSelectedItemId(item._id)}
              >
                <p className="p-1.5 break-before-all w-fit">{item?.name}</p>
                <p className="p-1.5">
                  {FormatCurrency(item?.costPlusMargin?.naira || 0)}
                </p>
                <span className="flex items-center justify-between">
                  <div className="relative">
                    {isLiking && isSelectedItemId === item._id ? (
                      <div className="absolute -right-1 -top-1.5">
                        <ColorRingSpinner height={50} width={50} />
                      </div>
                    ) : null}

                    <IconButton
                      className="p-1.5"
                      onClick={() => handleLikeUnlike(item._id)}
                    >
                      {item?.likesUnlike?.includes(user?._id) ? (
                        <Icon>favorite</Icon>
                      ) : (
                        <Icon>favorite_border</Icon>
                      )}
                    </IconButton>
                  </div>
                  <Button
                    variant="outlined"
                    sx={{ padding: 2, borderRadius: 12, height: "14px" }}
                    onClick={() => handleRentNumber(item)}
                    disabled={isRentingNumber}
                  >
                    {isRentingNumber && isSelectedItemId === item._id ? (
                      <ColorRingSpinner height={20} width={20} />
                    ) : (
                      "Rent"
                    )}
                  </Button>
                </span>
              </div>
            );
          })}
          {!isLoading && filteredData?.length < 1 ? (
            <NoDataFound width={100} />
          ) : null}
          {isLoading || filteredData?.length < 1 ? (
            <>
              {EMPTY_ARRAY?.map((item, i) => {
                return <SkeletonTypography key={i} />;
              })}
            </>
          ) : null}
        </div>
      </div>
      {/* <div className=" w-full">
        <Outlet />
      </div> */}
    </>
  );
};

export default NumbersServiceList;

export const OutletLayout = () => {
  return (
    <>
      <div className=" w-full">
        <Outlet />
      </div>
    </>
  );
};
