import React from "react";
import { ThreeCircles, MutatingDots, Vortex, ColorRing } from "react-loader-spinner";
import Lottie from "lottie-react";
import Loader from "../assets/lottie/Loader.json";
import NoData from "../assets/lottie/NoData.json";
import { primaryColor } from "../utils/Colors";
import { Backdrop } from "@mui/material";

function Spinner({ height, width }) {
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <MutatingDots
          height={height}
          width={width}
          color={`${primaryColor}`}
          secondaryColor={`${primaryColor}`}
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass="absolute backdrop-opacity-10 bg-white/10 h-full w-full m-auto flex justify-center items-center top-0 left-0 z-50"
          visible={true}
        />
      </Backdrop>
      {/* <div className="absolute flex justify-center items-center top-0 bottom-0 left-0 backdrop-opacity-10 bg-white/10 min-h-max h-screen w-full z-50 overflow-hidden">
        <MutatingDots
          height={height}
          width={width}
          color={`${primaryColor}`}
          secondaryColor={`${primaryColor}`}
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass="absolute backdrop-opacity-10 bg-white/10 h-full w-full m-auto flex justify-center items-center top-0 left-0 z-50"
          visible={true}
        />
      </div> */}
    </>
  );
}
export default Spinner;

export const SmallSpinner = ({ width, height }) => {
  return (
    <Vortex
      visible={true}
      height="30"
      width="30"
      ariaLabel="vortex-loading"
      wrapperStyle={{}}
      wrapperClass={"vortex-wrapper"}
      colors={["#4C50A9", "#", "#fff", "#4C50A9", "", "#fff"]}
    />
  );
};

export const ColorRingSpinner = ({ width = 30, height = 30 }) => {
  return (
    <ColorRing
      visible={true}
      height={height}
      width={width}
      ariaLabel="oval-loading"
      wrapperStyle={{}}
      wrapperClass={"oval-wrapper"}
      colors={["#4C50A9", "#fff", "#4C50A9", "#fff", "#4C50A9", "#fff"]}
    />
  );
};