import { createAction, createSlice } from "@reduxjs/toolkit";
import { logsPlugApi } from "apis/services/AuthApi";
import { logoutAction } from "apis/store/StoreAction";

const initialState = {
  user: "",
  token: ""
};

export const logout = createAction("auth/logout");

export const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, { payload }) => {
      state.user = { ...payload };
      state.token = { ...payload };
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(logoutAction, () => ({ ...initialState }))
      .addMatcher(
        logsPlugApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.user = { ...payload };
          state.token = { ...payload };
        }
      )
      .addMatcher(
        logsPlugApi.endpoints.setTransactionPin.matchFulfilled,
        (state, { payload }) => {
          console.log(payload);
          console.log({ ...state.user?.user });

          state.user = {
            ...state.user,
            user: { ...state.user?.user, wallet: payload?.wallet }
          };
        }
      );
  }
});

export const { setCredentials } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
